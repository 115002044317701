<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div>
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="SÖZLEŞME SATIRI" entityName="bm_sozlesmesatiri"></EntityHeader>

					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="aciklama">Açıklama</label>
								<InputText id="aciklama" type="text" v-model="mainData.aciklama" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bakiminYapildigiTarih">Bakımın Yapıldığı Tarihi</label>
								<Calendar showButtonBar id="bakiminYapildigiTarih" v-model="mainData.bakiminYapildigiTarih" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bakimYapildimi">Bakım Yapıldı mı?</label>
								<Dropdown id="bakimYapildimi" v-model="mainData.bakimYapildimi" :options="SM_bakimYapildimi" optionLabel="Value" optionValue="AttributeValue" placeholder="Bakım Yapıldı mı?" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="satirToplam">Satır Toplam</label>
								<InputNumber id="satirToplam" v-model="mainData.satirToplam" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="true" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>BAKIM BİLGİLERİ</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bakimSozlesmesi">Bakım Sözleşmesi</label>
								<Dropdown id="bakimSozlesmesi" v-model="mainData.bakimSozlesmesi" :options="SM_bakimSozlesmesi" optionLabel="Value" optionValue="AttributeValue" placeholder="Bakım Sözleşmesi" :disabled="isDisabled" @change="changeBakimSozlesmesi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="periyodikBakimSuresi">Periyodik Bakım Süresi</label>
								<InputNumber id="periyodikBakimSuresi" v-model="mainData.periyodikBakimSuresi" mode="decimal" locale="tr-TR" :minFractionDigits="0" :disabled="isDisabled" @input="inputPeriyodikBakimSuresi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bakimUcreti">Bakım Ücreti</label>
								<InputNumber id="bakimUcreti" v-model="mainData.bakimUcreti" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="isDisabled" @input="inputBakimUcreti" />
								<span v-if="v$.mainData.bakimUcreti.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bakimUcreti.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bakimToplami">Bakım Toplamı</label>
								<InputNumber id="bakimToplami" v-model="mainData.bakimToplami" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="true"  />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="spindleVibrasyonOlcumu">Spindle Vibrasyon Ölçümü</label>
								<InputNumber id="spindleVibrasyonOlcumu" v-model="mainData.spindleVibrasyonOlcumu" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="isDisabled"  />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="makineTeraziYapilmasi">Makine Terazi Yapılması</label>
								<InputNumber id="makineTeraziYapilmasi" v-model="mainData.makineTeraziYapilmasi" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="isDisabled"  />
							</div>
						</div>
					</div>
				</div>
			</div>	
			
			<div class="col-12">
				<div class="card">
					<h5>BALL BAR KALİBRASYON</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="ballBar">Ball Bar Kalibrasyon</label>
								<Dropdown id="ballBar" v-model="mainData.ballBar" :options="SM_ballBar" optionLabel="Value" optionValue="AttributeValue" placeholder="Ball Bar" :disabled="isDisabled" @change="changeBallBarKalibrasyon" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="ballBarGunSayisi">Ball Bar Gün Sayısı</label>
								<InputNumber id="ballBarGunSayisi" v-model="mainData.ballBarGunSayisi" mode="decimal" locale="tr-TR" :minFractionDigits="0" :disabled="isDisabled" @input="inputBallBarGunSayisi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="ballBarBirimFiyat">Ball Bar Birim Fiyat</label>
								<InputNumber id="ballBarBirimFiyat" v-model="mainData.ballBarBirimFiyat" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="isDisabled" @input="inputBallBarBirimFiyat" />
								<span v-if="v$.mainData.ballBarBirimFiyat.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.ballBarBirimFiyat.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="ballBarToplam">Ball Bar Toplam</label>
								<InputNumber id="ballBarToplam" v-model="mainData.ballBarToplam" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="true" />
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="col-12">
				<div class="card">
					<h5>LAZER KALİBRASYON</h5>
					<div class="grid"><div class="col-3">
							<div class="field p-fluid">
								<label for="lazersozlesmesi">Lazer Sözleşmesi</label>
								<Dropdown id="lazersozlesmesi" v-model="mainData.lazerSozlesmesi" :options="SM_lazersozlesmesi" optionLabel="Value" optionValue="AttributeValue" placeholder="Lazer Sözleşmesi" :disabled="isDisabled" @change="changeLazer"/>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="lazerGunSayisi">Lazer Gün Sayısı</label>
								<InputNumber id="lazerGunSayisi" v-model="mainData.lazerGunSayisi" mode="decimal" locale="tr-TR" :minFractionDigits="0" :disabled="isDisabled" @input="inputLazerGunSayisi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="lazerBirimFiyat">Lazer Birim Fiyat</label>
								<InputNumber id="lazerBirimFiyat" v-model="mainData.lazerBirimFiyat" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="isDisabled" @input="inputLazerBirimFiyat" />
								<span v-if="v$.mainData.lazerBirimFiyat.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.lazerBirimFiyat.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="lazerToplam">Lazer Toplam</label>
								<InputNumber id="lazerToplam" v-model="mainData.lazerToplam" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="true" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10020,
			mainData: {},
			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
					visible: () => !this.isDisabled
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
					visible: () => !this.isDisabled
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		this.OnLoad();
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDisabled: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Sözleşmeler');
					if (filtered.length > 0) {
						return filtered[0].isWrite == false;
					}
				}
			}

			return true;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Sözleşmeler');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		SM_bakimSozlesmesi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_bakimsozlemesi', 'bool');
		},
		SM_bakimYapildimi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_bakimyapildimi', 'bool');
		},
		SM_ballBar: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ballbar', 'bool');
		},
		SM_lazersozlesmesi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_lazersozlemesi', 'bool');
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Sözleşmeler');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	methods: {
		async OnLoad(){

			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.entityId != null) {
				try {
					debugger;
					let data = await this.crmService.getSozlesmeSatiriById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
					
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];

						this.mainData["entityId"] = this.entityId;

						if (this.mainData["bakiminYapildigiTarih"]) {
							this.mainData["bakiminYapildigiTarih"] = new Date(this.mainData.bakiminYapildigiTarih);
						}

						if (this.mainData["ballBarYapilanTarih"]) {
							this.mainData["ballBarYapilanTarih"] = new Date(this.mainData.ballBarYapilanTarih);
						}

						if (this.mainData["lazerTestiYapilanTarih"]) {
							this.mainData["lazerTestiYapilanTarih"] = new Date(this.mainData.lazerTestiYapilanTarih);
						}
					}
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				loader.hide();
			}

		},
		async OnSave(kapat) {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				let loader = this.$loading.show({
					container: this.$refs.smsContainer
				});

				try {
					this.mainData.entityId = this.entityId;

					const response = await this.crmService.getSozlesmeSatiriUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'sozlesmeler_reload'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
							else {
								this.OnLoad();
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				} finally {
					loader.hide();
				}
			}
		},
		async OnDelete(){
            this.deleteItem('bm_sozlesmesatiri', this.entityId);
        },
		changeBakimSozlesmesi(event) {
			if (event.value == true) {
				this.BakimHesapla(this.mainData.periyodikBakimSuresi, this.mainData.bakimUcreti);
			}
			else {
				this.mainData.periyodikBakimSuresi = 0;
				this.mainData.bakimUcreti = 0;
				this.mainData.bakimToplami = 0;
			}
		},
		inputPeriyodikBakimSuresi(event){
			this.BakimHesapla(event.value, this.mainData.bakimUcreti);
		},
		inputBakimUcreti(event){
			this.BakimHesapla(this.mainData.periyodikBakimSuresi, event.value);
		},
		BakimHesapla(bakimSuresi, bakimUcreti){
			debugger;
			if (this.mainData.bakimSozlesmesi == true) {
				if (bakimSuresi > 0 && bakimUcreti > 0) {
					this.mainData.bakimToplami = bakimSuresi * bakimUcreti;
				}
				else if(bakimUcreti > 0 && bakimSuresi == null) {
					this.mainData.bakimToplami = bakimUcreti;
				}
				else{
					this.mainData.bakimToplami = 0;
				}
			}
			else {
				this.mainData.bakimToplami = 0;
			}
		},

		changeBallBarKalibrasyon(event){
			if (event.value == true) {
				this.BallBarHesapla(this.mainData.ballBarGunSayisi, this.mainData.ballBarBirimFiyat);
			}
			else {
				this.mainData.ballBarGunSayisi = 0;
				this.mainData.ballBarBirimFiyat = 0;
				this.mainData.ballBarToplam = 0;
			}
		},
		inputBallBarGunSayisi(event){
			this.BallBarHesapla(event.value, this.mainData.ballBarBirimFiyat);
		},
		inputBallBarBirimFiyat(event){
			this.BallBarHesapla(this.mainData.ballBarGunSayisi, event.value);
		},
		BallBarHesapla(gunSayisi, birimFiyat){
			debugger;
			if (this.mainData.ballBar == true) {
				if (gunSayisi > 0 && birimFiyat > 0) {
					this.mainData.ballBarToplam = gunSayisi * birimFiyat;
				}
				else if(birimFiyat > 0 && gunSayisi == null) {
					this.mainData.ballBarToplam = birimFiyat;
				}
				else{
					this.mainData.ballBarToplam = 0;
				}
			}
			else {
				this.mainData.ballBarToplam = 0;
			}
		},

		changeLazer(event){
			if (event.value == true) {
				this.LazerHesapla(this.mainData.lazerGunSayisi, this.mainData.lazerBirimFiyat);
			}
			else {
				this.mainData.lazerGunSayisi = 0;
				this.mainData.lazerBirimFiyat = 0;
				this.mainData.lazerToplam = 0;
			}
		},
		inputLazerGunSayisi(event){
			this.LazerHesapla(event.value, this.mainData.lazerBirimFiyat);
		},
		inputLazerBirimFiyat(event){
			this.LazerHesapla(this.mainData.lazerGunSayisi, event.value);
		},
		LazerHesapla(gunSayisi, birimFiyat){
			debugger;
			if (this.mainData.lazerSozlesmesi == true) {
				if (gunSayisi > 0 && birimFiyat > 0) {
					this.mainData.lazerToplam = gunSayisi * birimFiyat;
				}
				else if(birimFiyat > 0 && gunSayisi == null) {
					this.mainData.lazerToplam = birimFiyat;
				}
				else{
					this.mainData.lazerToplam = 0;
				}
			}
			else {
				this.mainData.lazerToplam = 0;
			}
		},

		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	validations() {
		return {
			mainData: {
				bakimUcreti: {
					required: helpers.withMessage('Bakım Ücreti alanı dolu olmalıdır!', requiredIf(function(value, vm) {
						if (vm.bakimSozlesmesi == true) {
							return true;
						}
						else {
							return false;
						}
					})),
				},
				ballBarBirimFiyat: {
					required: helpers.withMessage('Ball Bar Birim Fiyat alanı dolu olmalıdır!', requiredIf(function(value, vm) {
						if (vm.ballBar == true) {
							return true;
						}
						else {
							return false;
						}
					})),
				},
				lazerBirimFiyat: {
					required: helpers.withMessage('Lazer Birim Fiyat alanı dolu olmalıdır!', requiredIf(function(value, vm) {
						if (vm.lazerSozlesmesi == true) {
							return true;
						}
						else {
							return false;
						}
					})),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
